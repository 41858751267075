module.exports = {

    wakuang1: {
        wodezhanghao: 'Mijn account',
        tuijianren: 'Verwijzer',
        fengxiang: 'Delen',
        dengjijindu:'Niveauvooruitgang',
        dangqiandengji:'Huidig niveau',
        haixu:'Nog nodig',
        shengji:'Upgrade',
        wodedaibi: 'Mijn token',
        wodeETF: 'Mijn ETF',
        zongji:'Totaal',
        jiazhi:'Waarde',
        wodekuangchi: 'Mijningszwembad',
        wodesuanli: 'Berekeningsvermogen',
        yilingqujiangli: 'Al geclaimd (U)',
        wodeshouyi: 'Mijn inkomsten',
        jiasushouyi: 'Versnelde inkomsten',
        zongji: 'Totaal (ETF)',
        jiazhi:'Waarde (U)',
        zongkuangchi:'Totaal mijningszwembad',
        chongzhinengliang: 'Energie opladen',
        wodetuandui: 'Mijn team',
        wodezhitui: 'Directe promotie',
        zhishuxiashu: 'Ondergeschikten',
        dengji:'Niveau',
        renshu:'Prestatie',
        jine: 'Hoeveelheid',
        jianglimingxi: 'Beloningsdetails',
        ETFlingqu: 'ETF-claim',
        shuliang: 'Hoeveelheid',
        shijian: 'Tijd',
        dangqianjiage: 'Huidige prijs',
    },
    wakuang2: {
        duihuan: 'Ruilen',
        jishijiaoyidaibi: 'Directe handelstoken',
        yue: 'Saldo',
        huadianshangxian: 'Slippage-limiet',
        jiaoyi: 'Transactie',
    },
    guanwang1: {
        us1: " ' Werelds toonaangevende gedecentraliseerde financiële platform",
        us2: 'Contract',
        us3: 'Word lid van het aangesloten bedrijf',
        us4: 'Onze doelstelling',
        us5: 'Kies beursgenoteerde fondsen als uw financiële hefboom met minder startkapitaal',
        us6:'·ETF-mijnwerk',
        us7:'Augustus 2024',
        us8: '·IM-communicatie',
        us9: 'November 2024',
        us10: '·ETF openbare ketentestnet',
        us11: 'Mei 2025',
        us12: '·ETF-hoofdnet',
        us13: 'Augustus 2025',
        us14:'·ETF-ecosysteem',
        us15:'December 2025',
        us16: 'ETF-tokeneconomie',
        us17: 'Tokenverdeling',
        us18: 'Totale aanbod',
        us19: 'Mijnwerk',
        us20:'Liquiditeit',
        us21:'Prestatie',
        us22: 'Waarom ontwikkelen we de ETF openbare keten',
        us23: 'Om ETF op de keten uit te kunnen voeren, wat opener en eerlijker zal zijn',
        us24: 'Kan ETF worden uitgevoerd op andere openbare ketens',
        us25: 'Het kan worden gerealiseerd. Echter, vanwege de kenmerken van de huidige openbare ketens, zal het worden gespeculeerd door nodes',
        us26: 'Het verschil tussen de ETF openbare keten en de EVM openbare keten',
        us27: 'Het GAS-mechanisme aangepast om ervoor te zorgen dat nodes niet vooruit in de rij kunnen gaan, waardoor financiën eerlijker en veiliger worden',
        us28: 'De reden waarom ETF niet is uitgevoerd op de openbare keten',
        us29: 'Het huidige wachtrijmechanisme van EVM staat nodes toe om in het interne netwerk in de rij te gaan, wat 100% speculatie mogelijk maakt',
        us30: 'Neem contact met ons op',
    },
    guanwang2: {
        us111: 'Homepage',
        us222: 'Whitepaper',
        us333: 'Stichting',
        us444: 'Promotie',
        us555: 'Mijnwerk',
        us666: 'Neem contact met ons op',
        us777: 'Kopiëren geslaagd',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Werving van supernode-partners',
    wodedizhi: 'Mijn adres',
    wodezhanghao: 'Mijn account',
    shouquandizhi: 'Autorisatieadres',
    shifoushichaojijiedian: 'Of het een supernode is',
    shichaojijiedian:'Is een supernode',
    bushichaojijiedian:'Niet een supernode',
    dianjijiaru: 'Klik om mee te doen',
},
    xuanfa: {
    baocuntupian:'Bewaar afbeelding',
    baocunchenggong:'Bewaren geslaagd',
},
    tishi: {
    us888:'Instellen geslaagd',
    us999:'Verbind de portemonnee a.u.b.',
    us1111:'Kopiëren geslaagd',
    us2222:'Operatie annuleren',
    us3333:'Kan niet met jezelf verbinden!',
    us4444:'Verbind eerst de portemonnee',
    us5555:'Voer het juiste adres in a.u.b.',
    us6666:'Aanmelding geslaagd!',
    us7777:'Het huidige adres is al een supernode!',
    us8888:'Voer een geldig adres in a.u.b.',
    us9999:'Ruilen geslaagd',
    us11111:'Verbinden...',
    us22222:'Autorizeren...',
    us33333:'Betalen...',
    },
    yilou: {
        us44444: 'Mijn inkomsten (bevroren)',
        us55555: 'In upgrade...',
        us66666: 'In ontvangst...',
        us77777: 'In toetreding...',
        us88888: 'In ruil...',
        us99999: 'Bevestigen',
        us111111: 'Annuleren'
    }
}