module.exports = {

    wakuang1: {
        wodezhanghao: 'Мой аккаунт',
        tuijianren: 'Реферер',
        fengxiang: 'Поделиться',
        dengjijindu:'Прогресс уровня',
        dangqiandengji:'Текущий уровень',
        haixu:'Все еще требуется',
        shengji:'Обновить',
        wodedaibi: 'Мой токен',
        wodeETF: 'Мой ETF',
        zongji:'Всего',
        jiazhi:'Значение',
        wodekuangchi: 'Майнинг-пул',
        wodesuanli: 'Вычислительная мощность',
        yilingqujiangli: 'Уже заявлено (U)',
        wodeshouyi: 'Мои доходы',
        jiasushouyi: 'Ускоренные доходы',
        zongji: 'Всего (ETF)',
        jiazhi:'Значение (U)',
        zongkuangchi:'Всего майнинг-пул',
        chongzhinengliang: 'Зарядить энергию',
        wodetuandui: 'Моя команда',
        wodezhitui: 'Прямая реклама',
        zhishuxiashu: 'Подчиненные',
        dengji:'Уровень',
        renshu:'Производительность',
        jine: 'Сумма',
        jianglimingxi: 'Подробности вознаграждения',
        ETFlingqu: 'Заявка ETF',
        shuliang: 'Количество',
        shijian: 'Время',
        dangqianjiage: 'Текущая цена',
    },
    wakuang2: {
        duihuan: 'Обмен',
        jishijiaoyidaibi: 'Моментальный торговый токен',
        yue: 'Баланс',
        huadianshangxian: 'Предел скольжения',
        jiaoyi: 'Транзакция',
    },
    guanwang1: {
        us1: "Ведущая в мире децентрализованная финансовая платформа",
        us2: 'Контракт',
        us3: 'Присоединяйтесь к аффилированной компании',
        us4: 'Наша цель',
        us5: 'Выберите биржевые фонды в качестве финансового рычага с меньшим начальным капиталом',
        us6:'· Майнинг ETF',
        us7:'Август 2024',
        us8: '· IM-общение',
        us9: 'Ноябрь 2024',
        us10: '· Тестовая сеть публичной цепи ETF',
        us11: 'Май 2025',
        us12: '· Основная сеть ETF',
        us13: 'Август 2025',
        us14:'· Экосистема ETF',
        us15:'Декабрь 2025',
        us16: 'Экономика токена ETF',
        us17: 'Распределение токена',
        us18: 'Общий объем предложения',
        us19: 'Майнинг',
        us20:'Ликвидность',
        us21:'Производительность',
        us22: 'Почему развивается публичная цепь ETF',
        us23: 'Чтобы позволить ETF выполняться в цепи, что будет более открыто и справедливо',
        us24: 'Может ли ETF выполняться на других публичных цепях',
        us25: 'Это может быть реализовано. Однако из-за характеристик механизма существующих публичных цепей он будет спекулироваться узлами',
        us26: 'Разница между публичной цепью ETF и публичной цепью EVM',
        us27: 'Изменен механизм GAS, чтобы гарантировать, что узлы не могут заранее вставать в очередь, делая финансы более справедливыми и безопасными',
        us28: 'Причина, почему ETF не был выполнен в публичной цепи',
        us29: 'Текущий механизм очереди EVM позволяет узлам вставать в очередь во внутренней сети, что может спекулировать на 100%',
        us30: 'Свяжитесь с нами',
    },
    guanwang2: {
        us111: 'Главная страница',
        us222: 'Белая книга',
        us333: 'Фонд',
        us444: 'Продвижение',
        us555: 'Майнинг',
        us666: 'Свяжитесь с нами',
        us777: 'Копирование успешно',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Набор партнеров суперузлов',
    wodedizhi: 'Мой адрес',
    wodezhanghao: 'Мой аккаунт',
    shouquandizhi: 'Адрес авторизации',
    shifoushichaojijiedian: 'Это суперузел',
    shichaojijiedian:'Это суперузел',
    bushichaojijiedian:'Это не суперузел',
    dianjijiaru: 'Нажмите, чтобы присоединиться',
},
    xuanfa: {
    baocuntupian:'Сохранить изображение',
    baocunchenggong:'Сохранение успешно',
},
    tishi: {
    us888:'Установка успешна',
    us999:'Пожалуйста, подключите кошелек',
    us1111:'Копирование успешно',
    us2222:'Отменить операцию',
    us3333:'Нельзя связывать с собой!',
    us4444:'Пожалуйста, подключите кошелек сначала',
    us5555:'Пожалуйста, введите правильный адрес',
    us6666:'Присоединение успешно!',
    us7777:'Текущий адрес уже является суперузлом!',
    us8888:'Пожалуйста, введите действительный адрес',
    us9999:'Обмен успешен',
    us11111:'Связь...',
    us22222:'Авторизация...',
    us33333:'Платеж...',
    },
    yilou: {
        us44444: 'Мои доходы (заморожены)',
        us55555: 'В процессе обновления...',
        us66666: 'В процессе получения...',
        us77777: 'В процессе присоединения...',
        us88888: 'В процессе обмена...',
        us99999: 'Подтвердить',
        us111111: 'Отменить'
    }
}