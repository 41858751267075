module.exports = {

    wakuang1: {
        wodezhanghao: 'Tài khoản của tôi',
        tuijianren: 'Người giới thiệu',
        fengxiang: 'Chia sẻ',
        dengjijindu:'Tiến độ cấp độ',
        dangqiandengji:'Cấp độ hiện tại',
        haixu:'Vẫn cần',
        shengji:'Nâng cấp',
        wodedaibi: 'Token của tôi',
        wodeETF: 'ETF của tôi',
        zongji:'Tổng cộng',
        jiazhi:'Giá trị',
        wodekuangchi: 'Mỏ đào',
        wodesuanli: 'Sức tính toán',
        yilingqujiangli: 'Đã nhận (U)',
        wodeshouyi: 'Thu nhập của tôi',
        jiasushouyi: 'Thu nhập tăng tốc',
        zongji: 'Tổng (ETF)',
        jiazhi:'Giá trị (U)',
        zongkuangchi:'Tổng mỏ đào',
        chongzhinengliang: 'Nạp năng lượng',
        wodetuandui: 'Đội của tôi',
        wodezhitui: 'Thúc đẩy trực tiếp',
        zhishuxiashu: 'Thuộc hạ',
        dengji:'Cấp độ',
        renshu:'Hiệu suất',
        jine: 'Số lượng',
        jianglimingxi: 'Chi tiết thưởng',
        ETFlingqu: 'Yêu cầu ETF',
        shuliang: 'Số lượng',
        shijian: 'Thời gian',
        dangqianjiage: 'Giá hiện tại',
    },
    wakuang2: {
        duihuan: 'Trao đổi',
        jishijiaoyidaibi: 'Token giao dịch tức thời',
        yue: 'Số dư',
        huadianshangxian: 'Giới hạn trượt giá',
        jiaoyi: 'Giao dịch',
    },
    guanwang1: {
        us1: 'Nền tảng tài chính phi tập trung hàng đầu thế giới',
        us2: 'Hợp đồng',
        us3: 'Tham gia công ty liên kết',
        us4: 'Mục tiêu của chúng tôi',
        us5: 'Chọn quỹ giao dịch trao đổi như đòn bẩy tài chính của bạn với vốn ban đầu ít',
        us6:'·Khai thác ETF',
        us7:'Tháng 8 năm 2024',
        us8: '·Truyền thông IM',
        us9: 'Tháng 11 năm 2024',
        us10: '·Mạng thử nghiệm chuỗi công khai ETF',
        us11: 'Tháng 5 năm 2025',
        us12: 'Mạng chính ETF',
        us13: 'Tháng 8 năm 2025',
        us14:'Hệ sinh thái ETF',
        us15:'Tháng 12 năm 2025',
        us16: 'Kinh tế học token ETF',
        us17: 'Phân phối token',
        us18: 'Tổng cung cấp',
        us19: 'Khai thác',
        us20:'Tính thanh khoản',
        us21:'Hiệu suất',
        us22: 'Tại sao phát triển chuỗi công khai ETF',
        us23: 'Để cho ETF được thực thi trên chuỗi, điều này sẽ công khai và công bằng hơn',
        us24: 'ETF có thể được thực thi trên các chuỗi công khai khác không',
        us25: 'Có thể thực hiện. Tuy nhiên, do đặc tính cơ chế của các chuỗi công khai hiện tại, nó sẽ bị các nút thao túng giá',
        us26: 'Sự khác biệt giữa chuỗi công khai ETF và chuỗi công khai EVM',
        us27: 'Thay đổi cơ chế GAS để đảm bảo các nút không thể xếp hàng trước, làm cho tài chính công bằng và an toàn hơn',
        us28: 'Lý do tại sao ETF chưa được thực thi trên chuỗi công khai',
        us29: 'Cơ chế xếp hàng hiện tại của EVM cho phép các nút xếp hàng trong mạng nội bộ, điều này có thể thao túng giá 100%',
        us30: 'Liên hệ với chúng tôi',
    },
    guanwang2: {
        us111: 'Trang chủ',
        us222: 'Bản trắng',
        us333: 'Quỹ',
        us444: 'Khuyến mãi',
        us555: 'Khai thác',
        us666: 'Liên hệ với chúng tôi',
        us777: 'Sao chép thành công',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Tuyển dụng đối tác nút siêu cấp',
    wodedizhi: 'Địa chỉ của tôi',
    wodezhanghao: 'Tài khoản của tôi',
    shouquandizhi: 'Địa chỉ ủy quyền',
    shifoushichaojijiedian: 'Có phải là nút siêu cấp không',
    shichaojijiedian:'Là nút siêu cấp',
    bushichaojijiedian:'Không phải nút siêu cấp',
    dianjijiaru: 'Nhấn để tham gia',
},
    xuanfa: {
    baocuntupian:'Lưu ảnh',
    baocunchenggong:'Lưu thành công',
},
    tishi: {
    us888:'Thiết lập thành công',
    us999:'Vui lòng kết nối ví',
    us1111:'Sao chép thành công',
    us2222:'Hủy thao tác',
    us3333:'Không thể liên kết với chính mình!',
    us4444:'Vui lòng kết nối ví trước',
    us5555:'Vui lòng nhập địa chỉ chính xác',
    us6666:'Tham gia thành công!',
    us7777:'Địa chỉ hiện tại đã là nút siêu cấp!',
    us8888:'Vui lòng nhập địa chỉ hợp lệ',
    us9999:'Trao đổi thành công',
    us11111:'Đang liên kết...',
    us22222:'Đang ủy quyền...',
    us33333:'Đang thanh toán...',
    },
    yilou: {
        us44444: 'Thu nhập của tôi (đóng băng)',
        us55555: 'Đang nâng cấp...',
        us66666: 'Đang nhận...',
        us77777: 'Đang tham gia...',
        us88888: 'Đang đổi...',
        us99999: 'Xác nhận',
        us111111: 'Hủy'
    }
}