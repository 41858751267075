module.exports = {

    wakuang1: {
        wodezhanghao: 'Mon compte',
        tuijianren: 'Référent',
        fengxiang: 'Partager',
        dengjijindu:'Progression de niveau',
        dangqiandengji:'Niveau actuel',
        haixu:'Toujours nécessaire',
        shengji:'Mettre à jour',
        wodedaibi: 'Mon token',
        wodeETF: 'Mon ETF',
        zongji:'Total',
        jiazhi:'Valeur',
        wodekuangchi: 'Piscine de minage',
        wodesuanli: 'Puissance de calcul',
        yilingqujiangli: 'Déjà réclamé (U)',
        wodeshouyi: 'Mes gains',
        jiasushouyi: 'Gains accélérés',
        zongji: 'Total (ETF)',
        jiazhi:'Valeur (U)',
        zongkuangchi:'Total de la piscine de minage',
        chongzhinengliang: 'Recharger l énergie',
        wodetuandui: 'Mon équipe',
        wodezhitui: 'Promotion directe',
        zhishuxiashu: 'Subordonné',
        dengji:'Niveau',
        renshu:'Performance',
        jine: 'Montant',
        jianglimingxi: 'Détails de la récompense',
        ETFlingqu: 'Demande d ETF',
        shuliang: 'Quantité',
        shijian: 'Temps',
        dangqianjiage: 'Prix actuel',
    },
    wakuang2: {
        duihuan: 'Échange',
        jishijiaoyidaibi: 'Token de trading instantané',
        yue: 'Solde',
        huadianshangxian: 'Limite de slippage',
        jiaoyi: 'Transaction',
    },
    guanwang1: {
        us1: "La plateforme financière décentralisée leader mondial",
        us2: 'Contrat',
        us3: 'Rejoignez la société affiliée',
        us4: 'Notre objectif',
        us5: 'Choisissez des fonds négociés en bourse en tant que levier financier avec moins de capital initial',
        us6:'·Mining ETF',
        us7:'Août 2024',
        us8: '·Communication IM',
        us9: 'Novembre 2024',
        us10: '·Testnet de la chaîne publique ETF',
        us11: 'Mai 2025',
        us12: '·Mainnet ETF',
        us13: 'Août 2025',
        us14:'·Écosystème ETF',
        us15:'Décembre 2025',
        us16: 'Économie des tokens ETF',
        us17: 'Distribution des tokens',
        us18: 'Offre totale',
        us19: 'Mining',
        us20:'Liquidité',
        us21:'Performance',
        us22: 'Pourquoi développer la chaîne publique ETF',
        us23: 'Pour permettre à l ETF d être exécuté sur la chaîne, ce qui sera plus ouvert et équitable',
        us24: 'L ETF peut-il être exécuté sur d autres chaînes publiques',
        us25: 'Cela peut être réalisé. Cependant, en raison des caractéristiques du mécanisme des chaînes publiques actuelles, il sera spéculé par les nœuds',
        us26: 'La différence entre la chaîne publique ETF et la chaîne publique EVM',
        us27: 'Modifié le mécanisme GAS pour garantir que les nœuds ne peuvent pas faire la queue à l avance, rendant les finances plus équitables et sécurisées',
        us28: 'La raison pour laquelle l ETF n a pas été exécuté sur la chaîne publique',
        us29: 'Le mécanisme d ordonnancement actuel de l EVM permet aux nœuds de faire la queue dans le réseau interne, ce qui peut spéculer à 100%',
        us30: 'Contactez-nous',
    },
    guanwang2: {
        us111: 'Page d accueil',
        us222: 'Whitepaper',
        us333: 'Fondation',
        us444: 'Promotion',
        us555: 'Mining',
        us666: 'Contactez-nous',
        us777: 'Copie réussie',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Recrutement de partenaires de nœuds super',
    wodedizhi: 'Mon adresse',
    wodezhanghao: 'Mon compte',
    shouquandizhi: 'Adresse d autorisation',
    shifoushichaojijiedian: 'Si c est un nœud super',
    shichaojijiedian:'C est un nœud super',
    bushichaojijiedian:'Ce n est pas un nœud super',
    dianjijiaru: 'Cliquez pour rejoindre',
},
    xuanfa: {
    baocuntupian:'Sauvegarder l image',
    baocunchenggong:'Sauvegarde réussie',
},
    tishi: {
    us888:'Configuration réussie',
    us999:'Veuillez connecter le portefeuille',
    us1111:'Copie réussie',
    us2222:'Annuler l opération',
    us3333:'Vous ne pouvez pas vous lier à vous-même!',
    us4444:'Veuillez connecter le portefeuille d abord',
    us5555:'Veuillez entrer l adresse correcte',
    us6666:'Inscription réussie!',
    us7777:'L adresse actuelle est déjà un nœud super!',
    us8888:'Veuillez entrer une adresse valide',
    us9999:'Échange réussi',
    us11111:'Liaison...',
    us22222:'Autorisation...',
    us33333:'Paiement...',
    },
    yilou: {
        us44444: 'Mes revenus (gelés)',
        us55555: 'En cours de mise à niveau...',
        us66666: 'En cours de récupération...',
        us77777: 'En cours de rejoindre...',
        us88888: 'En cours d échange...',
        us99999: 'Confirmer',
        us111111: 'Annuler'
    }
}