module.exports = {

    wakuang1: {
        wodezhanghao: 'Oma tili',
        tuijianren: 'Vinkkijä',
        fengxiang: 'Jaa',
        dengjijindu:'Tason eteneminen',
        dangqiandengji:'Nykyinen taso',
        haixu:'Vielä tarvitaan',
        shengji:'Päivitys',
        wodedaibi: 'Oma tokeni',
        wodeETF: 'Oma ETF',
        zongji:'Yhteensä',
        jiazhi:'Arvo',
        wodekuangchi: 'Kaivospool',
        wodesuanli: 'Laskentateho',
        yilingqujiangli: 'Olet jo vaatinut (U)',
        wodeshouyi: 'Oma tuloni',
        jiasushouyi: 'Kiihdyttämä tulot',
        zongji: 'Yhteensä (ETF)',
        jiazhi:'Arvo (U)',
        zongkuangchi:'Yhteensä kaivospool',
        chongzhinengliang: 'Lataa energiaa',
        wodetuandui: 'Oma tiimini',
        wodezhitui: 'Suora mainonta',
        zhishuxiashu: 'Alaiset',
        dengji:'Taso',
        renshu:'Suorituskyky',
        jine: 'Määrä',
        jianglimingxi: 'Palkkion yksityiskohdat',
        ETFlingqu: 'ETF-väite',
        shuliang: 'Määrä',
        shijian: 'Aika',
        dangqianjiage: 'Nykyinen hinta',
    },
    wakuang2: {
        duihuan: 'Vaihto',
        jishijiaoyidaibi: 'Hetken kuluttua kauppatokeni',
        yue: 'Saldo',
        huadianshangxian: 'Slippage-raja',
        jiaoyi: 'Kauppa',
    },
    guanwang1: {
        us1: "Maailman johtava hajautettu rahoitusalusta",
        us2: 'Sopimus',
        us3: 'Liity liitännäisyritykseen',
        us4: 'Meidän tavoitteemme',
        us5: 'Valitse pörssinostoja rahastot finanssipyrkimyksellesi vähemmällä aloituskapitalilla',
        us6:'·ETF-kaivaminen',
        us7:'Elokuu 2024',
        us8: '·IM-viestintä',
        us9: 'Marraskuu 2024',
        us10: '·ETF:n julkinen ketju testiverkko',
        us11: 'Toukokuu 2025',
        us12: '·ETF:n pääverkko',
        us13: 'Elokuu 2025',
        us14:'·ETF:n ekosysteemi',
        us15:'Joulukuu 2025',
        us16: 'ETF:n tokenien talous',
        us17: 'Tokenien jakautuminen',
        us18: 'Yhteensä tarjonta',
        us19: 'Kaivaminen',
        us20:'Käytettävissäolo',
        us21:'Suorituskyky',
        us22: 'Miksi kehitetään ETF:n julkista ketjua',
        us23: 'Jotta ETF voidaan suorittaa ketjussa, mikä on avoimempaa ja oikeudenmukaisempaa',
        us24: 'Voidaanko ETF suorittaa muilla julkisilla ketjuilla',
        us25: 'Se voidaan toteuttaa. Kuitenkin nykyisten julkisten ketjujen mekanismien ominaisuuksien vuoksi se voidaan spekuloida solmuilla',
        us26: 'Ero ETF:n julkisen ketjun ja EVM:n julkisen ketjun välillä',
        us27: 'Muokattiin GAS-mekanismia varmistaaksemme, ettei solmuja voi jonottaa etukäteen, jolloin rahoitus on oikeudenmukaisempaa ja turvallisempaa',
        us28: 'Syynä, miksi ETF:tä ei ole suoritettu julkisessa ketjussa',
        us29: 'Nykyinen EVM:n jonotusmekanismi sallii solmujen jonottamaan sisäverkossa, mikä voi tehdä 100% spekulointia',
        us30: 'Ota meihin yhteyttä',
    },
    guanwang2: {
        us111: 'Kotisivu',
        us222: 'Valkoinen paperi',
        us333: 'Säätiö',
        us444: 'Mainostus',
        us555: 'Kaivaminen',
        us666: 'Ota meihin yhteyttä',
        us777: 'Kopiointi onnistui',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Supernodin kumppanien värvääminen',
    wodedizhi: 'Oma osoite',
    wodezhanghao: 'Oma tili',
    shouquandizhi: 'Vahvistusosoite',
    shifoushichaojijiedian: 'Onko se supernodi',
    shichaojijiedian:'On supernodi',
    bushichaojijiedian:'Ei ole supernodi',
    dianjijiaru: 'Napsauta liittymään',
},
    xuanfa: {
    baocuntupian:'Tallentaa kuva',
    baocunchenggong:'Tallentaminen onnistui',
},
    tishi: {
    us888:'Asetuksen asettaminen onnistui',
    us999:'Ota yhteys lompakkoon',
    us1111:'Kopiointi onnistui',
    us2222:'Peruuta operaatio',
    us3333:'Et voi sitouttaa itseäsi!',
    us4444:'Ota ensin yhteys lompakkoon',
    us5555:'Anna oikea osoite',
    us6666:'Liityminen onnistui!',
    us7777:'Nykyinen osoite on jo supernodi!',
    us8888:'Anna kelvollinen osoite',
    us9999:'Vaihto onnistui',
    us11111:'Yhdistäminen...',
    us22222:'Vahvistaminen...',
    us33333:'Maksaminen...',
    },
    yilou: {
        us44444: 'Minun tuloni (jäädyttänyt)',
        us55555: 'Päivityksessä...',
        us66666: 'Noutamisessa...',
        us77777: 'Liittymisessä...',
        us88888: 'Vaihdossa...',
        us99999: 'Vahvista',
        us111111: 'Peruuta'
    }
}