<template>
    <div class="CutInternet" :class="{'active': active}">
        
        <div class="CutInternet-box">
            <video class="video" :controls="false" autoplay muted>
                <source :src="openVideo" type="video/mp4">
                您的浏览器不支持 video 标签。
            </video>
        </div>
    </div>
</template>

<style scoped lang="scss">
.CutInternet {
    width: 100vw;
    height: 100vh;
    // background-color: rgb(110, 191, 241);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: hidden;
    transition: all .3s;

    &.active {
        transform: translateY(-120vh);
    }

    .CutInternet-box {
        width: 100vw;
        height: 100vh;
        // position: absolute;
        // top: 0%;
        // left: 50%;
        // transform: translateX(-50%);
        overflow: hidden;

        .video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }

        .text-box {
            position: relative;
            text-align: left;

            .text {
                font-size: 18px;
                white-space: nowrap;
            }
            .text-1 {
                overflow: hidden;
                animation: show 3s infinite;
            }
            .text-2 {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .3;
            }
        }

        .image-box {
            position: relative;
            margin: 0 auto;
            width: 100px;

            .image {
                img {
                    width: 100px;
                    height: 100px;
                    display: block;
                    margin: 0 auto;
                }
            }
            .image-1 {
                overflow: hidden;
                animation: show 3s infinite;
            }
            .image-2 {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .3;
            }
        }
        
    }
}

@keyframes show {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
</style>
<script>


import openVideo from "@/assets/img/open.mp4"
import catWalkImage from "@/assets/img/cats/cat_walk.png"

export default {
    data() {
        return {
            active: false,
            openVideo,
            catWalkImage,
            times: 0,
            timer: null
        }
    },
    computed: {
      
    },
    mounted() {
        this.setTimer()
    },
    destroyed() {
        clearInterval(this.timer)
    },
    methods: {
        setTimer() {
            this.timer = setInterval( () => {
                if(this.times === 4) {
                    this.times = 0
                    this.close()
                }else {
                    this.times = this.times + 1
                }
            }, 1000)
        },
        close() {
            clearInterval(this.timer)
            this.active = true
        }
    }

}
</script>
