module.exports = {

    wakuang1: {
        wodezhanghao: 'Mitt konto',
        tuijianren: 'Refererare',
        fengxiang: 'Dela',
        dengjijindu:'Nivåframsteg',
        dangqiandengji:'Nuvarande nivå',
        haixu:'Är fortfarande nödvändig',
        shengji:'Uppgradera',
        wodedaibi: 'Mitt token',
        wodeETF: 'Min ETF',
        zongji:'Total',
        jiazhi:'Värde',
        wodekuangchi: 'Gruvpool',
        wodesuanli: 'Beräkningskraft',
        yilingqujiangli: 'Redan hävd (U)',
        wodeshouyi: 'Mina inkomster',
        jiasushouyi: 'Förorening av inkomsterna',
        zongji: 'Total (ETF)',
        jiazhi:'Värde (U)',
        zongkuangchi:'Total gruvpool',
        chongzhinengliang: 'Ladda energi',
        wodetuandui: 'Mitt team',
        wodezhitui: 'Direkt främjande',
        zhishuxiashu: 'Underordnade',
        dengji:'Nivå',
        renshu:'Prestanda',
        jine: 'Belopp',
        jianglimingxi: 'Belöning detaljer',
        ETFlingqu: 'ETF-förfrågan',
        shuliang: 'Antal',
        shijian: 'Tid',
        dangqianjiage: 'Nuvarande pris',
    },
    wakuang2: {
        duihuan: 'Bytte',
        jishijiaoyidaibi: 'Omedelbar handel token',
        yue: 'Saldo',
        huadianshangxian: 'Slippagegräns',
        jiaoyi: 'Transaktion',
    },
    guanwang1: {
        us1: "Världens ledande decentraliserade finansiella plattform",
        us2: 'Kontrakt',
        us3: 'Gå med i affiliatbolaget',
        us4: 'Vårt mål',
        us5: 'Välj börshandlade fonder som din finansiella hävstång med mindre startkapital',
        us6:'·ETF-gruvning',
        us7:'Augusti 2024',
        us8: '·IM-kommunikation',
        us9: 'November 2024',
        us10: '·ETF offentlig kedja testnät',
        us11: 'Maj 2025',
        us12: '·ETF huvudnät',
        us13: 'Augusti 2025',
        us14:'·ETF-ekosystem',
        us15:'December 2025',
        us16: 'ETF-tokenekonomi',
        us17: 'Tokenfördelning',
        us18: 'Totalförsörjning',
        us19: 'Gruvning',
        us20:'Likviditet',
        us21:'Prestanda',
        us22: 'Varför utveckla ETF offentliga kedjan',
        us23: 'För att göra det möjligt för ETF att köras på kedjan, vilket kommer att vara mer öppet och rättvist',
        us24: 'Kan ETF köras på andra offentliga kedjor',
        us25: 'Det kan realiseras. Dock kommer det på grund av mekanismkaraktärerna hos nuvarande offentliga kedjor att bli spekulerat av noder',
        us26: 'Skillnaden mellan ETF offentliga kedjan och EVM offentliga kedjan',
        us27: 'Ändrade GAS-mechanismen för att säkerställa att noder inte kan stå i kö före, vilket gör finansen mer rättvis och säker',
        us28: 'Anledningen till att ETF inte har körts på offentliga kedjor',
        us29: 'Den nuvarande kömekanismen för EVM tillåter noder att stå i kö i det interna nätverket, vilket kan göra spekulation 100%',
        us30: 'Kontakta oss',
    },
    guanwang2: {
        us111: 'Hemsida',
        us222: 'Vitbok',
        us333: 'Stiftelse',
        us444: 'Försäljning',
        us555: 'Gruvning',
        us666: 'Kontakta oss',
        us777: 'Kopiering lyckades',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Rekrytering av supernodpartners',
    wodedizhi: 'Mitt adress',
    wodezhanghao: 'Mitt konto',
    shouquandizhi: 'Autorisationsadress',
    shifoushichaojijiedian: 'Är det en supernod',
    shichaojijiedian:'Är en supernod',
    bushichaojijiedian:'Inte en supernod',
    dianjijiaru: 'Klicka för att gå med',
},
    xuanfa: {
    baocuntupian:'Spara bild',
    baocunchenggong:'Sparat framgångsrikt',
},
    tishi: {
    us888:'Inställningar lyckades',
    us999:'Var god koppla portföljen',
    us1111:'Kopiering lyckades',
    us2222:'Avbryt operation',
    us3333:'Kan inte binda med dig själv!',
    us4444:'Var god koppla portföljen först',
    us5555:'Var god ange rätt adress',
    us6666:'Gick med framgångsrikt!',
    us7777:'Den nuvarande adressen är redan en supernod!',
    us8888:'Var god ange en giltig adress',
    us9999:'Byte lyckades',
    us11111:'Binds...',
    us22222:'Autorisering...',
    us33333:'Betalar...',
    },
    yilou: {
        us44444: "Mina inkomster (frysta)",
        us55555: "I uppgradering...",
        us66666: "I hämtning...",
        us77777: "I anslutning...",
        us88888: "I bytesprocess...",
        us99999: "Bekräfta",
        us111111: "Avbryt"
    }
}