module.exports = {

    wakuang1: {
        wodezhanghao: 'Moje konto',
        tuijianren: 'Referent',
        fengxiang: 'Udostępnij',
        dengjijindu:'Postęp poziomu',
        dangqiandengji:'Aktualny poziom',
        haixu:'Nadal potrzebne',
        shengji:'Aktualizacja',
        wodedaibi: 'Mój token',
        wodeETF: 'Mój ETF',
        zongji:'Razem',
        jiazhi:'Wartość',
        wodekuangchi: 'Górnicza pula',
        wodesuanli: 'Moc obliczeniowa',
        yilingqujiangli: 'Już zgłoszone (U)',
        wodeshouyi: 'Moje zarobki',
        jiasushouyi: 'Przyspieszone zarobki',
        zongji: 'Razem (ETF)',
        jiazhi:'Wartość (U)',
        zongkuangchi:'Razem górnicza pula',
        chongzhinengliang: 'Doładowanie energii',
        wodetuandui: 'Mój zespół',
        wodezhitui: 'Bezpośrednia promocja',
        zhishuxiashu: 'Podwładni',
        dengji:'Poziom',
        renshu:'Wydajność',
        jine: 'Ilość',
        jianglimingxi: 'Szczegóły nagrody',
        ETFlingqu: 'Zgłoszenie ETF',
        shuliang: 'Ilość',
        shijian: 'Czas',
        dangqianjiage: 'Aktualna cena',
    },
    wakuang2: {
        duihuan: 'Wymiana',
        jishijiaoyidaibi: 'Token natychmiastowej wymiany',
        yue: 'Saldo',
        huadianshangxian: 'Limit poślizgu',
        jiaoyi: 'Transakcja',
    },
    guanwang1: {
        us1: "Światowa wiodąca zdecentralizowana platforma finansowa",
        us2: 'Kontrakt',
        us3: 'Dołącz do firmy partnerskiej',
        us4: 'Nasz cel',
        us5: 'Wybierz fundusze giełdowe jako swoją dźwignię finansową z mniejszym początkowym kapitałem',
        us6:'·Górnictwo ETF',
        us7:'Sierpień 2024',
        us8: '·Komunikacja IM',
        us9: 'Listopad 2024',
        us10: '·Testowa sieć publiczna ETF',
        us11: 'Maj 2025',
        us12: '·Sieć główna ETF',
        us13: 'Sierpień 2025',
        us14:'·Ekosystem ETF',
        us15:'Grudzień 2025',
        us16: 'Ekonomia tokenów ETF',
        us17: 'Dystrybucja tokenów',
        us18: 'Całkowita podaż',
        us19: 'Górnictwo',
        us20:'Płynność',
        us21:'Wydajność',
        us22: 'Dlaczego rozwijamy publiczną sieć ETF',
        us23: 'Aby umożliwić wykonanie ETF na łańcuchu, co będzie bardziej otwarte i sprawiedliwe',
        us24: 'Czy ETF może być wykonywane na innych publicznych łańcuchach',
        us25: 'Może być zrealizowane. Jednak ze względu na cechy mechanizmu aktualnych publicznych łańcuchów, będzie spekulowane przez węzły',
        us26: 'Różnica między publiczną siecią ETF a siecią publiczną EVM',
        us27: 'Zmodyfikowano mechanizm GAS, aby zapewnić, że węzły nie mogą kolejkować się z góry, czyniąc finanse bardziej sprawiedliwymi i bezpiecznymi',
        us28: 'Powód, dla którego ETF nie zostało wykonane na publicznej sieci',
        us29: 'Aktualny mechanizm kolejkowania EVM pozwala węzłom kolejkować się w wewnętrznej sieci, co może prowadzić do spekulacji w 100%',
        us30: 'Skontaktuj się z nami',
    },
    guanwang2: {
        us111: 'Strona główna',
        us222: 'Biała księga',
        us333: 'Fundacja',
        us444: 'Promocja',
        us555: 'Górnictwo',
        us666: 'Skontaktuj się z nami',
        us777: 'Kopiowanie zakończone sukcesem',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Nabor partnerów superwęzłów',
    wodedizhi: 'Mój adres',
    wodezhanghao: 'Moje konto',
    shouquandizhi: 'Adres autoryzacji',
    shifoushichaojijiedian: 'Czy to jest superwęzeł',
    shichaojijiedian:'Jest superwęzłem',
    bushichaojijiedian:'Nie jest superwęzłem',
    dianjijiaru: 'Kliknij, aby dołączyć',
},
    xuanfa: {
    baocuntupian:'Zapisz obraz',
    baocunchenggong:'Zapisanie zakończone sukcesem',
},
    tishi: {
    us888:'Ustawienie zakończone sukcesem',
    us999:'Proszę połączyć portfel',
    us1111:'Kopiowanie zakończone sukcesem',
    us2222:'Anuluj operację',
    us3333:'Nie można się połączyć z samym sobą!',
    us4444:'Proszę najpierw połączyć portfel',
    us5555:'Proszę wprowadzić poprawny adres',
    us6666:'Dołączenie zakończone sukcesem!',
    us7777:'Aktualny adres jest już superwęzłem!',
    us8888:'Proszę wprowadzić prawidłowy adres',
    us9999:'Wymiana zakończona sukcesem',
    us11111:'Łączenie...',
    us22222:'Autoryzacja...',
    us33333:'Płacenie...',
    },
    yilou: {
        us44444: 'Moje dochody (zamrożone)',
        us55555: 'W trakcie aktualizacji...',
        us66666: 'W trakcie odbioru...',
        us77777: 'W trakcie dołączania...',
        us88888: 'W trakcie wymiany...',
        us99999: 'Potwierdź',
        us111111: 'Anuluj'
    }
}