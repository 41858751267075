module.exports = {

    wakuang1: {
        wodezhanghao: 'Мій рахунок',
        tuijianren: 'Референт',
        fengxiang: 'Поділитися',
        dengjijindu:'Прогрес рівня',
        dangqiandengji:'Поточний рівень',
        haixu:'Все ще потрібний',
        shengji:'Оновити',
        wodedaibi: 'Мій токен',
        wodeETF: 'Мій ETF',
        zongji:'Загалом',
        jiazhi:'Значення',
        wodekuangchi: 'Майнінкова басейн',
        wodesuanli: 'Комп ютерна потужність',
        yilingqujiangli: 'Вже стверджено (U)',
        wodeshouyi: 'Мої доходи',
        jiasushouyi: 'Прискорені доходи',
        zongji: 'Загалом (ETF)',
        jiazhi:'Значення (U)',
        zongkuangchi:'Загальний майнінкова басейн',
        chongzhinengliang: 'Перезарядити енергію',
        wodetuandui: 'Моя команда',
        wodezhitui: 'Пряма реклама',
        zhishuxiashu: 'Підлеглі',
        dengji:'Рівень',
        renshu:'Виконання',
        jine: 'Кількість',
        jianglimingxi: 'Деталі винагороди',
        ETFlingqu: 'Вимога ETF',
        shuliang: 'Кількість',
        shijian: 'Час',
        dangqianjiage: 'Поточна ціна',
    },
    wakuang2: {
        duihuan: 'Обмін',
        jishijiaoyidaibi: 'Миттєвий торгівельний токен',
        yue: 'Баланс',
        huadianshangxian: 'Ліміт ковзання',
        jiaoyi: 'Транзакція',
    },
    guanwang1: {
        us1: "Свійostí ведуча децентралізована фінансова платформа у світі",
        us2: 'Контракт',
        us3: 'Приєднатися до афілірованої компанії',
        us4: 'Наш ціль',
        us5: 'Виберіть фонди, торгуючі на біржі, як фінансовий лівер з меншим початковим капіталом',
        us6:'· Майнінга ETF',
        us7:'Вересень 2024 року',
        us8: '· IM спілкування',
        us9: 'Листопад 2024 року',
        us10: '· Тестова мережа публічної ланцюжка ETF',
        us11: 'Травень 2025 року',
        us12: '· Основна мережа ETF',
        us13: 'Вересень 2025 року',
        us14:'· Екосистема ETF',
        us15:'Грудень 2025 року',
        us16: 'Економіка токена ETF',
        us17: 'Розподіл токена',
        us18: 'Загальна постачання',
        us19: 'Майнінга',
        us20:'Ліквідність',
        us21:'Виконання',
        us22: 'Чому розробляти публічну ланцюжку ETF',
        us23: 'Щоб дозволити ETF виконуватися на ланцюжці, це буде більш відкритим і справедливим',
        us24: 'Чи може ETF виконуватися на інших публічних ланцюжках',
        us25: 'Може бути реалізовано. Однак з огляду на характеристики механізму поточних публічних ланцюжок, він буде визначатися вузлами',
        us26: 'Різниця між публічною ланцюжкою ETF та EVM',
        us27: 'Змінено механізм GAS, щоб гарантувати, що вузли не можуть чергуватися заздалегідь, зробити фінанси більш справедливими і безпечними',
        us28: 'Причина, чому ETF ще не виконується на публічній ланцюжці',
        us29: 'Поточний механізм черги EVM дозволяє вузлам чергуватися в внутрішній мережі, що може здійснювати арбітраж на 100%',
        us30: 'Зв яжіться з нами',
    },
    guanwang2: {
        us111: 'Головна сторінка',
        us222: 'Белая книга',
        us333: 'Фонд',
        us444: 'Промоція',
        us555: 'Майнінга',
        us666: 'Зв яжіться з нами',
        us777: 'Копіювання успішне',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Рекрутування партнерів супер вузла',
    wodedizhi: 'Моя адреса',
    wodezhanghao: 'Мій рахунок',
    shouquandizhi: 'Адреса авторизації',
    shifoushichaojijiedian: 'Чи є це супер вузол',
    shichaojijiedian:'Це супер вузол',
    bushichaojijiedian:'Це не супер вузол',
    dianjijiaru: 'Натисніть, щоб приєднатися',
},
    xuanfa: {
    baocuntupian:'Зберегти зображення',
    baocunchenggong:'Збереження успішне',
},
    tishi: {
    us888:'Налаштування успішне',
    us999:'Будь ласка, з єднайте гаманець',
    us1111:'Копіювання успішне',
    us2222:'Скасувати операцію',
    us3333:'Не можна прив язати до себе!',
    us4444:'Будь ласка, спочатку з єднайте гаманець',
    us5555:'Будь ласка, введіть правильну адресу',
    us6666:'Приєднання успішне!',
    us7777:'Поточна адреса вже є супер вузлом!',
    us8888:'Будь ласка, введіть дійсну адресу',
    us9999:'Обмін успішний',
    us11111:'Прив язка...',
    us22222:'Авторизація...',
    us33333:'Платіж...',
    },
    yilou: {
        us44444: 'Мої доходи (заморожені)',
        us55555: 'У процесі оновлення...',
        us66666: 'У процесі отримання...',
        us77777: 'У процесі приєднання...',
        us88888: 'У процесі обміну...',
        us99999: 'Підтвердити',
        us111111: 'Скасувати'
    }
}