<style lang="scss" scoped>
    .Bottom-Nav {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 60px;
        background-color: rgba(255,255,255,.7);
        padding: 10px 10px;
        padding-bottom:10px;

        // &.active {
        //     background-color: rgba(0,0,0,.5);
        // }

        .Bottom-Nav__box {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .Bottom-Nav__cell {
                // height: 100%;
                text-align: center;
                width: 20%;
                & * {
                    transition: all .3s;
                }

                .Bottom-Nav__cell-icon {
                    height: 50px;
                    img {
                        height: 100%;
                    }
                }
                .Bottom-Nav__cell-title {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 25px;
                    // padding: 3px 8px;
                    border-radius: 4px;
                    transition: all .3s;
                    font-weight: 700;
                }
                &.active {
                    .Bottom-Nav__cell-icon {
                        // transform: scale(1.2) rotate(20deg);
                        transform: scale(1.2);
                    }
                    .Bottom-Nav__cell-title {
                        // color: #dff4fc;
                        // text-shadow: 0px 0px 5px rgba(0,0,0,1);
                        background-color: rgba(0,0,0,.1);
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
</style>
<template>
    <transition :name="$JinConfig.bottom" appear>
        <div class="Bottom-Nav" :class="{active: active === '/My'}">
            <div class="Bottom-Nav__box">
                    <div v-for="(item,index) of navs" 
                        :key="index" 
                        class="Bottom-Nav__cell"
                        :style="$global.moduleShowAnimation(index * 2)"
                        @click="Select(item, index)"
                        :class="{active: item.path === active }" >
                            <transition :name="$JinConfig.bottom" appear>
                                <div v-if="item.type == 1" class="Bottom-Nav__cell-icon">
                                    <img :src="item.icon" alt="">
                                </div>
                                <div v-else class="Bottom-Nav__cell-title font-color__theme">
                                    <span :class="{'font-color__theme-glod': item.path === active}">
                                        {{ item.title }}
                                    </span>
                                </div>
                            </transition>
                    </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name: 'Bottom-Nav',
    data() {
        return {
            active: '/',
            navs: [
            //     {
            //     title: '私募',
            //     icon: icon1,
            //     path: "/Index"
            // },
            {
                title: '挖矿',
                path: "/"
            },{
                title: 'NFT',
                path: "/NFT"
            }]
        }
    },
    watch: {
        "$route.path"(val) {
            this.active = val
        }
    },
    created() {
        const path = this.$route.path
        this.active = path
    },
    methods: {
        Select(item) {
            this.active = item.path
            this.$router.replace(`${item.path}?shareurl=${this.$route.query.shareurl || ''}`)
        }
    },
}
</script>