module.exports = {

    wakuang1: {
        wodezhanghao: 'Mein Konto',
        tuijianren: 'Referrer',
        fengxiang: 'Teilen',
        dengjijindu:'Levelfortschritt',
        dangqiandengji:'Aktuelles Level',
        haixu:'Noch benötigt',
        shengji:'Upgrade',
        wodedaibi: 'Mein Token',
        wodeETF: 'Mein ETF',
        zongji:'Gesamt',
        jiazhi:'Wert',
        wodekuangchi: 'Mining-Pool',
        wodesuanli: 'Rechenleistung',
        yilingqujiangli: 'Bereits beansprucht (U)',
        wodeshouyi: 'Meine Einnahmen',
        jiasushouyi: 'Beschleunigte Einnahmen',
        zongji: 'Gesamt (ETF)',
        jiazhi:'Wert (U)',
        zongkuangchi:'Gesamt-Mining-Pool',
        chongzhinengliang: 'Energie aufladen',
        wodetuandui: 'Mein Team',
        wodezhitui: 'Direkte Promotion',
        zhishuxiashu: 'Untergebene',
        dengji:'Level',
        renshu:'Leistung',
        jine: 'Betrag',
        jianglimingxi: 'Belohnungsdetails',
        ETFlingqu: 'ETF-Anspruch',
        shuliang: 'Menge',
        shijian: 'Zeit',
        dangqianjiage: 'Aktueller Preis',
    },
    wakuang2: {
        duihuan: 'Austausch',
        jishijiaoyidaibi: 'Instant-Trading-Token',
        yue: 'Saldo',
        huadianshangxian: 'Slippage-Limit',
        jiaoyi: 'Transaktion',
    },
    guanwang1: {
        us1: "Die weltweit führende dezentrale Finanzplattform",
        us2: 'Vertrag',
        us3: 'Treten Sie dem Partnerunternehmen bei',
        us4: 'Unser Ziel',
        us5: 'Wählen Sie börsengehandelte Fonds als Ihre Finanzhebelwirkung mit weniger Anfangskapital',
        us6:'·ETF-Mining',
        us7:'August 2024',
        us8: '·IM-Kommunikation',
        us9: 'November 2024',
        us10: '·ETF-Public-Chain-Testnet',
        us11: 'Mai 2025',
        us12: '·ETF-Mainnet',
        us13: 'August 2025',
        us14:'·ETF-Ökosystem',
        us15:'Dezember 2025',
        us16: 'ETF-Token-Ökonomie',
        us17: 'Token-Verteilung',
        us18: 'Gesamtangebot',
        us19: 'Mining',
        us20:'Liquidität',
        us21:'Leistung',
        us22: 'Warum die ETF-Public-Chain entwickeln',
        us23: 'Um die ETF auf der Kette ausführen zu können, was offener und fairer sein wird',
        us24: 'Kann die ETF auf anderen öffentlichen Ketten ausgeführt werden',
        us25: 'Es kann realisiert werden. Allerdings wird es aufgrund der Mechanismenmerkmale der aktuellen öffentlichen Ketten von Knoten spekuliert',
        us26: 'Der Unterschied zwischen der ETF-Public-Chain und der EVM-Public-Chain',
        us27: 'Den GAS-Mechanismus modifiziert, um sicherzustellen, dass Knoten nicht im Voraus in die Warteschlange kommen, wodurch Finanzen fairer und sicherer werden',
        us28: 'Der Grund, warum die ETF nicht auf der öffentlichen Kette ausgeführt wurde',
        us29: 'Der aktuelle Warteschlangenmechanismus von EVM erlaubt Knoten, sich im internen Netzwerk in die Warteschlange zu stellen, was 100% Arbitrage ermöglichen kann',
        us30: 'Kontaktieren Sie uns',
    },
    guanwang2: {
        us111: 'Startseite',
        us222: 'Whitepaper',
        us333: 'Stiftung',
        us444: 'Promotion',
        us555: 'Mining',
        us666: 'Kontaktieren Sie uns',
        us777: 'Kopieren erfolgreich',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Einstellung von Superknoten-Partnern',
    wodedizhi: 'Meine Adresse',
    wodezhanghao: 'Mein Konto',
    shouquandizhi: 'Autorisierungsadresse',
    shifoushichaojijiedian: 'Ob es ein Superknoten ist',
    shichaojijiedian:'Ist ein Superknoten',
    bushichaojijiedian:'Ist kein Superknoten',
    dianjijiaru: 'Klicken Sie zum Beitreten',
},
    xuanfa: {
    baocuntupian:'Bild speichern',
    baocunchenggong:'Speichern erfolgreich',
},
    tishi: {
    us888:'Einstellung erfolgreich',
    us999:'Bitte verbinden Sie die Wallet',
    us1111:'Kopieren erfolgreich',
    us2222:'Operation abbrechen',
    us3333:'Können sich nicht mit sich selbst verbinden!',
    us4444:'Bitte verbinden Sie zuerst die Wallet',
    us5555:'Bitte geben Sie die richtige Adresse ein',
    us6666:'Beitritt erfolgreich!',
    us7777:'Die aktuelle Adresse ist bereits ein Superknoten!',
    us8888:'Bitte geben Sie eine gültige Adresse ein',
    us9999:'Austausch erfolgreich',
    us11111:'Verbindung...',
    us22222:'Autorisierung...',
    us33333:'Zahlung...',
    },
    yilou: {
        us44444: 'Meine Einnahmen (gefroren)',
        us55555: 'Im Upgrade...',
        us66666: 'Im Abholen...',
        us77777: 'Im Beitritt...',
        us88888: 'Im Umtausch...',
        us99999: 'Bestätigen',
        us111111: 'Abbrechen'
    }
}