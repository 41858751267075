module.exports = {

    wakuang1: {
        wodezhanghao: 'Ang aking account',
        tuijianren: 'Referrer',
        fengxiang: 'Ibahagi',
        dengjijindu:'Progresyo ng antas',
        dangqiandengji:'Kasalukuyang antas',
        haixu:'Kailangan pa rin',
        shengji:'Pag-upgrade',
        wodedaibi: 'Ang aking token',
        wodeETF: 'Ang aking ETF',
        zongji:'Kabuuang',
        jiazhi:'Halaga',
        wodekuangchi: 'Mining pool',
        wodesuanli: 'Computing power',
        yilingqujiangli: 'Nakakuha na (U)',
        wodeshouyi: 'Ang aking kita',
        jiasushouyi: 'Pinabilisang kita',
        zongji: 'Kabuuang (ETF)',
        jiazhi:'Halaga (U)',
        zongkuangchi:'Kabuuang mining pool',
        chongzhinengliang: 'Mag-recharge ng enerhiya',
        wodetuandui: 'Ang aking team',
        wodezhitui: 'Direktang pag-promote',
        zhishuxiashu: 'Mga subordinate',
        dengji:'Antas',
        renshu:'Pagganap',
        jine: 'Dami',
        jianglimingxi: 'Mga detalye ng gantimpala',
        ETFlingqu: 'Claim ng ETF',
        shuliang: 'Dami',
        shijian: 'Oras',
        dangqianjiage: 'Kasalukuyang presyo',
    },
    wakuang2: {
        duihuan: 'Palitan',
        jishijiaoyidaibi: 'Instant trading token',
        yue: 'Balance',
        huadianshangxian: 'Limitasyon ng slippage',
        jiaoyi: 'Transaksyon',
    },
    guanwang1: {
        us1: "Ang nangungunang decentralized financial platform sa mundo",
        us2: 'Kontrakt',
        us3: 'Sumali sa affiliate kumpanya',
        us4: 'Ang aming layunin',
        us5: 'Pumili ng exchange-traded funds bilang iyong financial leverage na may mas kaunting initial capital',
        us6:'·ETF mining',
        us7:'Agosto 2024',
        us8: '·IM komunikasyon',
        us9: 'Nobyembre 2024',
        us10: '·ETF public chain testnet',
        us11: 'Mayo 2025',
        us12: '·ETF mainnet',
        us13: 'Agosto 2025',
        us14:'·ETF ecosystem',
        us15:'Disyembre 2025',
        us16: 'ETF token economics',
        us17: 'Pagpapamahagi ng token',
        us18: 'Kabuuang supply',
        us19: 'Mining',
        us20:'Likididad',
        us21:'Pagganap',
        us22: 'Bakit bumuo ng ETF public chain',
        us23: 'Upang maisagawa ang ETF sa chain, na mas magiging bukas at patas',
        us24: 'Maaaring maisagawa ang ETF sa iba pang public chains',
        us25: 'Maaaring maisakatuparan. Gayunpaman, dahil sa mga katangian ng mekanismo ng mga kasalukuyang public chains, maaaring maarbitrage ng mga node',
        us26: 'Ang pagkakaiba sa pagitan ng ETF public chain at ang EVM public chain',
        us27: 'Binago ang GAS mekanismo upang matiyak na ang mga node ay hindi maaaring pumila nang maaga, na ginagawang mas patas at ligtas ang finance',
        us28: 'Ang dahilan kung bakit hindi pa maisagawa ang ETF sa public chain',
        us29: 'Ang kasalukuyang queuing mekanismo ng EVM ay nagpapahintulot sa mga node na pumila sa internal network, na maaaring mag-arbitrage ng 100%',
        us30: 'Makipag-ugnay sa amin',
    },
    guanwang2: {
        us111: 'Homepage',
        us222: 'Whitepaper',
        us333: 'Foundation',
        us444: 'Promotion',
        us555: 'Mining',
        us666: 'Makipag-ugnay sa amin',
        us777: 'Kopya na matagumpay',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Recruitment ng mga super node partner',
    wodedizhi: 'Ang aking address',
    wodezhanghao: 'Ang aking account',
    shouquandizhi: 'Authorization address',
    shifoushichaojijiedian: 'Kung ito ay isang super node',
    shichaojijiedian:'Ito ay isang super node',
    bushichaojijiedian:'Hindi ito isang super node',
    dianjijiaru: 'I-click upang sumali',
},
    xuanfa: {
    baocuntupian:'I-save ang larawan',
    baocunchenggong:'Matagumpay na nai-save',
},
    tishi: {
    us888:'Matagumpay na pag-setup',
    us999:'Mangyaring ikonekta ang wallet',
    us1111:'Matagumpay na kopya',
    us2222:'Kanselahin ang operasyon',
    us3333:'Hindi ka maaaring ikabit sa iyong sarili!',
    us4444:'Mangyaring ikonekta muna ang wallet',
    us5555:'Mangyaring ipasok ang tamang address',
    us6666:'Matagumpay na sumali!',
    us7777:'Ang kasalukuyang address ay isang super node na!',
    us8888:'Mangyaring ipasok ang wastong address',
    us9999:'Matagumpay na palitan',
    us11111:'Ibinibigay...',
    us22222:'I-authorize...',
    us33333:'Nagbabayad...',
    },
    yilou: {
        us44444: 'Ang aking kita (nakatatakip)',
        us55555: 'Sa proseso ng pag-upgrade...',
        us66666: 'Sa proseso ng pagkuha...',
        us77777: 'Sa proseso ng pagpasok...',
        us88888: 'Sa proseso ng palitan...',
        us99999: 'Kumpirmahin',
        us111111: 'Kanselahin'
    }
}