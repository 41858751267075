module.exports = {

    wakuang1: {
        wodezhanghao: 'Mi cuenta',
        tuijianren: 'Referente',
        fengxiang: 'Compartir',
        dengjijindu:'Progreso de nivel',
        dangqiandengji:'Nivel actual',
        haixu:'Todavía necesario',
        shengji:'Actualizar',
        wodedaibi: 'Mi token',
        wodeETF: 'Mi ETF',
        zongji:'Total',
        jiazhi:'Valor',
        wodekuangchi: 'Piscina minera',
        wodesuanli: 'Potencia de cálculo',
        yilingqujiangli: 'Ya reclamado (U)',
        wodeshouyi: 'Mis ingresos',
        jiasushouyi: 'Ingresos acelerados',
        zongji: 'Total (ETF)',
        jiazhi:'Valor (U)',
        zongkuangchi:'Total de piscina minera',
        chongzhinengliang: 'Recargar energía',
        wodetuandui: 'Mi equipo',
        wodezhitui: 'Promoción directa',
        zhishuxiashu: 'Subordinados',
        dengji:'Nivel',
        renshu:'Rendimiento',
        jine: 'Cantidad',
        jianglimingxi: 'Detalles de recompensa',
        ETFlingqu: 'Reclamación ETF',
        shuliang: 'Cantidad',
        shijian: 'Tiempo',
        dangqianjiage: 'Precio actual',
    },
    wakuang2: {
        duihuan: 'Intercambio',
        jishijiaoyidaibi: 'Token de comercio instantáneo',
        yue: 'Saldo',
        huadianshangxian: 'Límite de deslizamiento',
        jiaoyi: 'Transacción',
    },
    guanwang1: {
        us1: "La plataforma financiera descentralizada líder en el mundo",
        us2: 'Contrato',
        us3: 'Únase a la compañía afiliada',
        us4: 'Nuestro objetivo',
        us5: 'Elija fondos negociados en bolsa como su palanca financiera con menos capital inicial',
        us6:'·Minería ETF',
        us7:'Agosto de 2024',
        us8: '·Comunicación IM',
        us9: 'Noviembre de 2024',
        us10: '·Testnet de cadena pública ETF',
        us11: 'Mayo de 2025',
        us12: '·Mainnet ETF',
        us13: 'Agosto de 2025',
        us14:'·Ecosistema ETF',
        us15:'Diciembre de 2025',
        us16: 'Economía de token ETF',
        us17: 'Distribución de token',
        us18: 'Suministro total',
        us19: 'Minería',
        us20:'Liquidez',
        us21:'Rendimiento',
        us22: '¿Por qué desarrollar la cadena pública ETF?',
        us23: 'Para permitir que el ETF se ejecute en la cadena, lo que será más abierto y justo',
        us24: '¿Puede el ETF ejecutarse en otras cadenas públicas?',
        us25: 'Se puede realizar. Sin embargo, debido a las características del mecanismo de las cadenas públicas actuales, será aprovechado por los nodos',
        us26: 'La diferencia entre la cadena pública ETF y la cadena pública EVM',
        us27: 'Modificó el mecanismo GAS para garantizar que los nodos no puedan hacer cola por adelantado, haciendo que las finanzas sean más justas y seguras',
        us28: 'La razón por la que el ETF no se ha ejecutado en la cadena pública',
        us29: 'El mecanismo de cola actual de EVM permite que los nodos hagan cola en la red interna, lo que puede realizar arbitraje al 100%',
        us30: 'Contáctenos',
    },
    guanwang2: {
        us111: 'Página de inicio',
        us222: 'Whitepaper',
        us333: 'Fundación',
        us444: 'Promoción',
        us555: 'Minería',
        us666: 'Contáctenos',
        us777: 'Copia exitosa',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: 'Reclutamiento de socios de nodo super',
    wodedizhi: 'Mi dirección',
    wodezhanghao: 'Mi cuenta',
    shouquandizhi: 'Dirección de autorización',
    shifoushichaojijiedian: 'Si es un nodo super',
    shichaojijiedian:'Es un nodo super',
    bushichaojijiedian:'No es un nodo super',
    dianjijiaru: 'Haga clic para unirse',
},
    xuanfa: {
    baocuntupian:'Guardar imagen',
    baocunchenggong:'Guardado exitoso',
},
    tishi: {
    us888:'Configuración exitosa',
    us999:'Por favor, conecte la billetera',
    us1111:'Copia exitosa',
    us2222:'Cancelar operación',
    us3333:'No puede vincularse consigo mismo!',
    us4444:'Por favor, conecte la billetera primero',
    us5555:'Por favor, ingrese la dirección correcta',
    us6666:'Inscripción exitosa!',
    us7777:'La dirección actual ya es un nodo super!',
    us8888:'Por favor, ingrese una dirección válida',
    us9999:'Intercambio exitoso',
    us11111:'Vinculando...',
    us22222:'Autorizando...',
    us33333:'Pagando...',
    },
    yilou: {
        us44444: 'Mis ingresos (congelados)',
        us55555: 'En actualización...',
        us66666: 'En proceso de recepción...',
        us77777: 'En proceso de incorporación...',
        us88888: 'En proceso de cambio...',
        us99999: 'Confirmar',
        us111111: 'Cancelar'
    }
}