import Vue from 'vue'
import VueI18n from 'vue-i18n';

if (!localStorage.getItem('lang') || localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == 'zh' || localStorage.getItem('lang') == 'zh-ft') {
    localStorage.setItem('lang', '英语')
}

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || '英语', //使用localStorage缓存到本地，当下次使用时可默认当前使用语言
    messages: {

        '阿拉伯语': require('./阿拉伯语    العربية  '),
        '巴西语': require('./巴西语   Brasil  '),
        '波兰语': require('./波兰语  Polska'),
        '德语': require('./德语   Deutsch '),


        '俄语': require('./俄语  Русский'),
        '法语': require('./法语 Français'),
        '繁体中文': require('./繁体中文'),


        '菲律宾': require('./菲律宾  Filipino'),
        '芬兰语': require('./芬兰语  Suomi'),
        '韩语': require('./韩语  한국어'),


        '荷兰语': require('./荷兰语   Nederlands'),
        '简体中文': require('./简体中文'),
        '罗曼娜': require('./罗曼娜  Romance'),


        '马扎尔语': require('./马扎尔语   Magyar nyelv'),
        '葡萄牙语': require('./葡萄牙语  Português'),
        '日本語': require('./日本語'),


        '瑞典': require('./瑞典语 Sverige'),
        '土耳其语': require('./土耳其语  Türkçe'),
        '津巴布韦': require('./绍纳语   zimbabwe'),


        '乌克兰语': require('./乌克兰语   Українська мова'),
        '西班牙语': require('./西班牙语  Español'),
        '意大利语': require('./意大利语   Italiano'),

        '印度尼西亚语': require('./印度尼西亚语  Bahasa Indonesia'),
        '英语': require('./英语 English'),
        '越南语': require('./越南语   Tiếng Việt'),

    }
})
export default {
    t: (args) => {
        return i18n.tc.call(i18n, args);
    }
} 
