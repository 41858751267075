module.exports = {

    wakuang1: {
        wodezhanghao: '내 계정',
        tuijianren: '추천인',
        fengxiang: '공유',
        dengjijindu:'레벨 진행',
        dangqiandengji:'현재 레벨',
        haixu:'여전히 필요',
        shengji:'업그레이드',
        wodedaibi: '내 토큰',
        wodeETF: '내 ETF',
        zongji:'합계',
        jiazhi:'값',
        wodekuangchi: '채굴 풀',
        wodesuanli: '연산력',
        yilingqujiangli: '이미 청구 (U)',
        wodeshouyi: '내 수익',
        jiasushouyi: '가속된 수익',
        zongji: '합계 (ETF)',
        jiazhi:'값 (U)',
        zongkuangchi:'총 채굴 풀',
        chongzhinengliang: '에너지 충전',
        wodetuandui: '내 팀',
        wodezhitui: '직접 홍보',
        zhishuxiashu: '부하',
        dengji:'레벨',
        renshu:'성과',
        jine: '금액',
        jianglimingxi: '보상 상세',
        ETFlingqu: 'ETF 청구',
        shuliang: '수량',
        shijian: '시간',
        dangqianjiage: '현재 가격',
    },
    wakuang2: {
        duihuan: '교환',
        jishijiaoyidaibi: '즉시 거래 토큰',
        yue: '잔액',
        huadianshangxian: '슬리피지 제한',
        jiaoyi: '거래',
    },
    guanwang1: {
        us1: "세계 최고의 분산형 금융 플랫폼",
        us2: '계약',
        us3: '제휴 회사에 가입',
        us4: '우리의 목표',
        us5: '초기 자본이 적은 금융 레버리지로 거래소 거래 펀드를 선택',
        us6:'·ETF 채굴',
        us7:'2024년 8월',
        us8: '·IM 통신',
        us9: '2024년 11월',
        us10: '·ETF 공개 체인 테스트넷',
        us11: '2025년 5월',
        us12: '·ETF 메인넷',
        us13: '2025년 8월',
        us14:'·ETF 생태계',
        us15:'2025년 12월',
        us16: 'ETF 토큰 경제학',
        us17: '토큰 분배',
        us18: '총 공급량',
        us19: '채굴',
        us20:'유동성',
        us21:'성과',
        us22: 'ETF 공개 체인을 개발하는 이유',
        us23: 'ETF가 체인에서 실행될 수 있도록 하여 더 개방적이고 공정하게',
        us24: 'ETF가 다른 공개 체인에서 실행될 수 있나',
        us25: '실현될 수 있다. 그러나 현재 공개 체인의 메커니즘 특성으로 인해 노드에 의해 차익 거래될 수 있음',
        us26: 'ETF 공개 체인과 EVM 공개 체인의 차이',
        us27: 'GAS 메커니즘을 수정하여 노드가 미리 대기열에 들어가지 못하도록 하여 금융을 더 공정하고 안전하게',
        us28: 'ETF가 공개 체인에서 실행되지 않은 이유',
        us29: '현재 EVM의 대기열 메커니즘은 노드가 내부 네트워크에서 대기열에 들어갈 수 있게 하여 100% 차익 거래가 가능',
        us30: '연락 주세요',
    },
    guanwang2: {
        us111: '홈페이지',
        us222: '화이트페이퍼',
        us333: '기금',
        us444: '프로모션',
        us555: '채굴',
        us666: '연락 주세요',
        us777: '복사 성공',
    },
    chaojijiedian: {
    chaojijiedianhehuorenzhaomu: '슈퍼 노드 파트너 모집',
    wodedizhi: '내 주소',
    wodezhanghao: '내 계정',
    shouquandizhi: '승인 주소',
    shifoushichaojijiedian: '슈퍼 노드인지 여부',
    shichaojijiedian:'슈퍼 노드입니다',
    bushichaojijiedian:'슈퍼 노드가 아닙니다',
    dianjijiaru: '참가하기 클릭',
},
    xuanfa: {
    baocuntupian:'사진 저장',
    baocunchenggong:'저장 성공',
},
    tishi: {
    us888:'설정 성공',
    us999:'지갑을 연결하세요',
    us1111:'복사 성공',
    us2222:'작업 취소',
    us3333:'자기 자신과 연결할 수 없습니다!',
    us4444:'먼저 지갑을 연결하세요',
    us5555:'정확한 주소를 입력하세요',
    us6666:'가입 성공!',
    us7777:'현재 주소는 이미 슈퍼 노드입니다!',
    us8888:'유효한 주소를 입력하세요',
    us9999:'교환 성공',
    us11111:'연결 중...',
    us22222:'권한 부여 중...',
    us33333:'결제 중...',
    },
    yilou: {
        us44444: '나의 수익(동결)',
        us55555: '업그레이드 중...',
        us66666: '수령 중...',
        us77777: '가입 중...',
        us88888: '교환 중...',
        us99999: '확인',
        us111111: '취소'
    }
}